import './App.css';
import Home from './components/Home.js';

const App = () => {
  return (
    
    <div className="App">
      <Home />

    </div>
  );
}

export default App;
